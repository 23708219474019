/**
 * Anchor color map
 */
export const ANCHOR_COLORS = {
    default: { r: 0, g: 98, b: 204 },
    bright: { r: 36, g: 80, b: 255 },
    muted: { r: 2, g: 119, b: 189 },
} as const

/**
 * Text colors used for forground on theme colors
 */
export const TEXT_COLORS = {
    dark: { r: 20, g: 20, b: 20 }, // #141414
    light: { r: 255, g: 255, b: 255 }, // #FFFFFF
} as const

/**
 * Fallback colors for theme variables
 */
export const THEME_FALLBACKS = {
    primary: { r: 3, g: 2, b: 78, a: 1 },
    secondary: { r: 115, g: 166, b: 255, a: 1 },
    tertiary: { r: 102, g: 221, b: 181, a: 1 },
} as const

/**
 * Get an empty instance of white label theme model
 *
 */
export const getEmptyThemeModel = (): Partial<WhitelabelThemeModel> => ({
    isDefault: false,
    headingFont: null,
    headingSize: 4,
    headingColor: null,
    paragraphFont: null,
    paragraphColor: null,
    bgColor: null,
    backgroundImage: null,
})

/**
 * Get empty instance of white label config model
 */
export const getEmptyConfigModel = (): Partial<WhitelabelConfigModel> => ({
    name: '',
    isDefault: false,
    primaryText: null,
    secondaryText: null,
    tertiaryText: null,
    anchorText: 'default',
    navType: 'primary',
    logoSquare: null,
    logoRectangle: null,
    logoLogin: null,
    loginBackgroundImage: null,
    colors: [],

})