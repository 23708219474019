import moment from 'moment'
/**
* Add or subtract days from a date
*
* @param days number
* @return { Date }
*/
export function dateSumDays(days: number, date: string | Date = new Date()): Date {
    date = new Date(date)
    date.setDate(date.getDate() + days)

    return date
}

export function getYear(years: number): number {
    const date = new Date()

    return date.getFullYear() + years
}

export function getLabelLastDays(days: number): [string, number, { days: number }] {
    return ['TERMS.LAST_DAYS', days, { days }]
}

export function daysBetweenDates(start: Date, end: Date): number {
    if (typeof start === 'string') start = new Date(start)
    if (typeof end === 'string') end = new Date(end)
    const difference = end.getTime() - start.getTime()

    return Math.ceil(difference / (1000 * 3600 * 24))
}

export function dateToDisplay(date: Date): string {
    return moment(date).format('DD MMM YYYY')
}

export function dateToString(date: Date): string {
    return moment(date).format('YYYY-MM-DD')
}
