<template>
    <div class="tiptap-editor__counter">
        <span
            v-if="count >= 0"
            v-text="count"
        />
        <span
            v-if="count >= 0 && limit"
            v-text="' / '"
        />
        <span
            v-if="limit"
            v-text="limit"
        />
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    export default Vue.extend({
        props: {
            count: {
                type: Number as PropType<number>,
                required: true,
            },
            limit: {
                type: Number as PropType<number>,
                required: true,
            },
        },
    })
</script>
