function hasAttended(session?: SessionAttentionModel): boolean {
    return session?.attended === true
}

function isAttending(session?: SessionAttentionModel): boolean {
    return session?.userStatus === 'attending' && typeof session?.userStatus !== 'boolean'
}

function isWaiting(session?: SessionAttentionModel): boolean {
    return session?.userStatus === 'waitingList'
}

function isDeclining(session?: SessionAttentionModel): boolean {
    return session?.userStatus === 'notAttending'
}

function isInvited(session?: SessionAttentionModel): boolean {
    return session?.userStatus === 'invited'
}

function isRequested(session?: SessionAttentionModel): boolean {
    return session?.userStatus === 'requested'
}

function isPlanned(session?: SessionAttentionModel): boolean {
    return session?.status === 'PLANNED'
}

export function canRegisterSession(
    sessionStatus?: SessionStatus,
    markAttendance?: attendanceType,
    userStatus?: UserHasCourseSession['status'],
    attended?: UserHasCourseSession['attended'],
): boolean {
    return (
        sessionStatus === 'HELD'
        && markAttendance === 'user'
        && (userStatus === 'attending' || userStatus === 'requested')
        && attended === null
    )
}

export function getAttendingSession(sessions?: SessionModel[]): SessionModel | null {
    return sessions?.find((session) => {
        return session.user_has_course_session?.status === 'attending'
            && typeof session.user_has_course_session?.attended !== 'boolean'
    }) ?? null
}

export function getWaitingSession(sessions?: SessionModel[]): SessionModel | null {
    return sessions?.find((session) => {
        return session.user_has_course_session?.status === 'waitingList'
            && typeof session.user_has_course_session?.attended !== 'boolean'
    }) ?? null
}

function format(session?: SessionAttentionModel | SessionModel | null): SessionAttentionModel | undefined {
    if (!session)
        return

    if ('user_has_course_session' in session) {
        return {
            attended: session.user_has_course_session?.attended || null,
            userStatus: session.user_has_course_session?.status || null,
            status: session.status,
            signupDeadline: session.signup_deadline,
            dates: session.dates,
        }
    }

    return session
}

export function getUserStates(session?: SessionAttentionModel): SessionUserStatesModel {
    return {
        hasAttended: hasAttended(session),
        isAttending: isAttending(session),
        isPlanned: isPlanned(session),
        isDeclining: isDeclining(session),
        isInvited: isInvited(session),
        isWaiting: isWaiting(session),
        isRequested: isRequested(session),
    }
}

export function getUserVariant(
    isExplorable: boolean,
    session?: SessionAttentionModel | SessionModel | null,
): string | null {
    const formatted = format(session)
    const result = getUserStates(formatted)

    if (!formatted?.userStatus) {
        if (isExplorable || !result.isPlanned) return null

        return 'gray'
    }

    if (typeof formatted?.attended === 'boolean')
        return 'gray'

    if (result.isAttending)
        return result.isPlanned ? 'success' : 'gray'

    if (formatted.userStatus === 'invited' && result.isPlanned)
        return 'danger'

    if (result.isRequested)
        return result.isPlanned ?  'warning' : 'gray'

    if (result.isDeclining)
        return result.isPlanned ? 'danger' : 'gray'

    if (result.isWaiting)
        return result.isPlanned ? 'warning' : 'gray'

    return 'gray'
}
