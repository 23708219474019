import store from '@store'

const showWidget = (): boolean =>  {
    const organization: AppConfig = store.get('AppConfig/organization')
    const activeUserType: ActiveUserType = store.get('Auth/activeUserType')

    return organization.id !== 636 && activeUserType === 'admin' && NODE_ENV !== 'development'
}

export const clearHubspot = (): void => {
    if (window.HubSpotConversations){
        window.HubSpotConversations.widget.refresh()
        window.HubSpotConversations.clear({ resetWidget: true })
    }
}

export const refreshHubspot = (): void => {
    if (!window.HubSpotConversations)
        return

    if (!showWidget()){
        window.HubSpotConversations.widget.remove()
    } else
        window.HubSpotConversations.widget.refresh()
}

export const initHubspot = async (): Promise<void> => {
    const user: UserModel = store.get('Auth/user')

    if (!showWidget()) {
        if (window.HubSpotConversations)
            window.HubSpotConversations.widget.remove()

        return
    }

    const config: HubspotSetting =  {
        enableWidgetCookieBanner: false,
        disableAttachment: false,
        loadImmediately: false,
    }

    // Only add them to hubspot if they are super admins.
    if (user.user_type?.super_user === 1){
        const data =  await store.dispatch('Auth/Hubspot/validate')

        config.identificationEmail = data.email
        config.identificationToken = data.token
    }

    window.hsConversationsSettings = config


    if (window.HubSpotConversations){
        // Used for debugging hubspot will be removed
        window.HubSpotConversations.widget.load()
        window.HubSpotConversations.widget.refresh()
    } else {
        window.hsConversationsOnReady = [
            (): void => {
                // Used for debugging hubspot will be removed
                window.HubSpotConversations.widget.load()
            },
        ]
    }
}

