import Vue from 'vue'
import moment from 'moment'
import { toHMS } from '@utils'

function getFormats(format: string | string[], fallbacks: string[]): { startFormat: string; endFormat: string } {
    return {
        startFormat: Array.isArray(format) ? format[0] : format || fallbacks[0],
        endFormat: Array.isArray(format) ? format[1] : format || fallbacks[1],
    }
}

/**
 * Format date for display
 *
 * @param value Incoming date to transform
 */
Vue.filter('date', (value: string, format = 'll'): string => {
    if (value) {
        return moment(value).format(format)
    }

    return value
})

/**
 * Format two dates as a range with shorted display within
 * same month or year
 *
 * @param value Incoming date(s) to transform
 */
Vue.filter('daterange', (value: string | string[], format: string | string[] = 'LL'): string => {
    const singleFormat = Array.isArray(format) ? format[0] : format
    const dates = Array.isArray(value)
        ? value.map((d) => moment(d))
        : [moment(value)]


    const startDate = moment.min(dates)
    const endDate = moment.max(dates)

    // If only one date or two identical, return only one date
    if (dates.length === 1 || startDate.isSame(endDate, 'day')) {
        return startDate.format(singleFormat)
    }

    if (startDate.year() !== endDate.year()) {
        return `${startDate.format(singleFormat)} - ${endDate.format(singleFormat)}`
    }

    if (startDate.month() === endDate.month()) {
        const { startFormat, endFormat } = getFormats(format, ['D.', 'D. MMMM YYYY'])

        return `${startDate.format(startFormat)} - ${endDate.format(endFormat)}`
    }

    const { startFormat, endFormat } = getFormats(format, ['D. MMMM', 'D. MMMM YYYY'])

    return `${startDate.format(startFormat)} - ${endDate.format(endFormat)}`
})

/**
 * Format two dates in hour, min, format as a range with shorted display within
 * same month or year
 *
 * @param value Incoming date(s) to transform
 */
Vue.filter('timerange', (value: string | [string, string], format = 'HH:mm'): string => {
    const times = Array.isArray(value)
        ? value
        : [value]
    // If only one time or two identical, return only one time
    if (times.length === 1 || moment(times[0]).isSame(times[1])) {
        return moment(times[0]).format(format)
    }
    const startTime = moment(times[0])
    const endTime = moment(times[1])

    return `${startTime.format(format)} - ${endTime.format(format)}`
})

/**
 * Format time for display
 *
 * @param value Incoming time to transform
 */
Vue.filter('time', (value: string): string => {
    if (value) {
        return value.replace(/(:\d{2}| [AP]M)$/, '')
    }

    return value
})

/**
 * Display relative time
 *
 * @param value Incoming date to transform
 * @param threshold Threshold in days - whith in what timefrime from now
 * until past to display relative time
 * @param suffix Show suffix for fromNow value
 * @param format Date format to use when relative time is not in threshold
 */
Vue.filter('fromNow', (value: string, threshold: number, suffix = false, format = 'll'): string => {
    if (!value) return value

    const momentValue = moment(value)

    if (!threshold) {
        return momentValue.fromNow(suffix)
    }

    const momentThreshold = moment().subtract(threshold, 'days')

    if (momentValue.isBefore(momentThreshold)) {
        return momentValue.format(format)
    }

    return momentValue.fromNow(suffix)
})

/**
 * Format seconds for readable display
 *
 * @param value Seconds
 */
Vue.filter('seconds', (value: number): string | null => {
    if (!value) return null

    return moment.duration(value * 1000).humanize()
})

/**
 * Format seconds to HMS
 */
Vue.filter('hms', (value: number, options: Parameters<typeof toHMS>[1]): string | null => {
    if (!value) return null

    const { h, m, s } = toHMS(value, options)
    let str = ''
    if (h) str = str + `${h}h `
    if (m) str = str + `${m}m `
    if (s) str = str + `${s}s `

    return str.trim()
})