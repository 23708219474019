import Vue from 'vue'

/**
 * Lowercase string
 *
 * @param value
 */
Vue.filter('lowercase', (value: string): string => {
    if (!value) return ''

    return value.toLowerCase()
})

/**
 * Uppercase string
 *
 * @param value
 */
Vue.filter('uppercase', (value: string): string => {
    if (!value) return ''

    return value.toUpperCase()
})

/**
 * Capitalize string
 *
 * @param value
 */
Vue.filter('capitalize', (value: string): string => {
    if (!value) return ''

    return value.charAt(0).toUpperCase() + value.slice(1)
})

/**
 * Truncate string
 *
 * @param value
 */
Vue.filter('truncate', (value: string, length: number, clamp: string): string => {
    length = length || 80
    clamp = clamp || '...'

    if (!value || value.length <= length) {
        return value
    }

    length = length - clamp.length

    return value.substring(0, length) + clamp
})
