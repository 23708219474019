import { Module } from 'vuex'
import Http from '@utils/Http'

type  HubspotToken = {
    token: string;
}
const store: Module<any, any> = {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
        /**
         * Issue a new token for user to hubspot als
         */
        async validate(): Promise<HubspotToken> {
            const { data } = await Http.api().post(`api2/hubspot/validate/user`)

            return data
        },
    },
}

export default store
