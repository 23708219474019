<template>
    <b-dropdown
        v-if="extensions.has(extension)"
        v-tooltip="tooltipOpts"
        :toggle-class="{
            'btn-icon': !$slots['button-content'],
            'text-primary': editor.isEditable && (!$slots['button-content'] && isActive)
        }"
        variant="outline-light"
        :disabled="!editor.isEditable"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #button-content>
            <slot name="button-content">
                <fa
                    v-if="icon"
                    :icon="icon && Array.isArray(icon) ? icon : ['fal', icon]"
                />
            </slot>
        </template>
        <slot v-bind="getScopedOptions()" />
    </b-dropdown>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { BDropdown } from 'bootstrap-vue'
    import { Inject } from './TiptapEditorProvider.vue'

    export default (Vue as VueConstructor<Vue & Inject>).extend({
        name: 'TiptapToolbarDropdown',

        components: {
            BDropdown,
        },
        inject: ['editor', 'extensions'],

        props: {
            /**
             * Icon to display in button
             */
            icon: {
                type: [String, Array] as PropType<string | string[]>,
                default: null,
            },
            /**
             * Tooltip label
             */
            label: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Type of extension, can define multiple
             */
            extension: {
                type: [String, Array] as PropType<string | string[]>,
                required: true,
            },
        },

        computed: {
            isActive(): boolean {
                const extensions = Array.isArray(this.extension)
                    ? this.extension
                    : [this.extension]

                return extensions.some((ext) => this.editor.isActive(ext))
            },

            tooltipOpts(): object {
                return {
                    title: this.label,
                    disabled: !this.editor.isEditable,
                }
            },
        },

        methods: {
            getScopedOptions(): Record<string, any> {
                const extensions = Array.isArray(this.extension) ? this.extension : [this.extension]

                return extensions.reduce((acc, item) => {
                    acc[item] = this.extensions.getOptions(item)

                    return acc
                }, {} as Record<string, any>)
            },
        },


    })
</script>