<template>
    <div class="tiptap-toolbar-top d-flex flex-wrap">
        <tiptap-dropdown-heading />
        <tiptap-button-group v-if="extensions.has(['bold', 'italic', 'underline', 'strikethrough'])">
            <tiptap-button
                icon="bold"
                label="Bold"
                extension="bold"
                command="toggleBold"
                shortcut="Mod+B"
            />
            <tiptap-button
                icon="italic"
                label="Italic"
                extension="italic"
                command="toggleItalic"
                shortcut="Mod+I"
            />
            <tiptap-button
                icon="underline"
                label="Underline"
                extension="underline"
                command="toggleUnderline"
                shortcut="Mod+U"
            />
            <tiptap-button
                icon="strikethrough"
                label="Strikethrough"
                extension="strike"
                command="toggleStrike"
                shortcut="Mod+Shift+X"
            />
        </tiptap-button-group>

        <tiptap-button-group v-if="extensions.has(['textColor', 'fontFamily'])">
            <tiptap-dropdown
                #default="{ textColor }"
                extension="textColor"
                icon="palette"
                label="Color"
            >
                <b-tabs
                    small
                    fill
                >
                    <b-tab
                        v-if="textColor.text"
                        :title="$t('TIPTAP.TEXT_COLOR')"
                    >
                        <div class="d-flex flex-wrap">
                            <div
                                v-for="(item, key) in textColor.colors"
                                :key="key"
                                class="p-1 w-25"
                            >
                                <tiptap-button
                                    class="btn-icon"
                                    :style="{
                                        background: item,
                                    }"
                                    :label="''"
                                    extension="color"
                                    command="setColor"
                                    :command-params="item"
                                    pill
                                />
                            </div>
                            <div class="p-1 w-25">
                                <tiptap-button
                                    variant="white"
                                    class="btn-icon border diagonal-line"
                                    extension="color"
                                    command="unsetColor"
                                    pill
                                />
                            </div>
                        </div>
                    </b-tab>
                    <b-tab
                        v-if="textColor.highlight"
                        :title="$t('TIPTAP.HIGHLIGHT')"
                    >
                        <div class="d-flex flex-wrap">
                            <div
                                v-for="(item, key) in textColor.colors"
                                :key="key"
                                class="p-1 w-25"
                            >
                                <tiptap-button
                                    class="btn-icon"
                                    :style="{
                                        background: item,
                                    }"
                                    :label="''"
                                    extension="highlight"
                                    command="setHighlight"
                                    :command-params="{ color: item }"
                                    pill
                                />
                            </div>
                            <div class="p-1 w-25">
                                <tiptap-button
                                    variant="white"
                                    class="btn-icon border diagonal-line"
                                    extension="highlight"
                                    command="unsetHighlight"
                                    pill
                                />
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </tiptap-dropdown>

            <tiptap-dropdown
                #default="{ fontFamily }"
                extension="fontFamily"
                icon="font"
                label="Font family"
            >
                <tiptap-button
                    v-for="(font, key) in fontFamily.fonts"
                    :key="key"
                    :style="font.value ? `font-family: ${font.value}` : null"
                    :label="font.text"
                    extension="fontFamily"
                    command="setFontFamily"
                    :command-params="font.value"
                    child
                />
            </tiptap-dropdown>
        </tiptap-button-group>
        <tiptap-button-group v-if="extensions.has(['list', 'textAlign'])">
            <tiptap-dropdown
                extension="list"
                icon="list"
                label="Lists"
            >
                <tiptap-button
                    icon="list-ul"
                    :label="'Bullet list'"
                    extension="bulletList"
                    command="toggleBulletList"
                    shortcut="Mod+Shift+8"
                    child
                />
                <tiptap-button
                    icon="list-ol"
                    :label="'Ordered list'"
                    extension="orderedList"
                    command="toggleOrderedList"
                    shortcut="Mod+Shift+7"
                    child
                />
            </tiptap-dropdown>
            <tiptap-dropdown
                extension="textAlign"
                icon="align-left"
                label="Text align"
            >
                <tiptap-button
                    icon="align-left"
                    label="Align left"
                    extension="textAlign"
                    command="setTextAlign"
                    command-params="left"
                    shortcut="Mod+Shift+L"
                    child
                />
                <tiptap-button
                    icon="align-right"
                    label="Align right"
                    extension="textAlign"
                    command="setTextAlign"
                    command-params="right"
                    shortcut="Mod+Shift+R"
                    child
                />
                <tiptap-button
                    icon="align-center"
                    label="Align center"
                    extension="textAlign"
                    command="setTextAlign"
                    command-params="center"
                    shortcut="Mod+Shift+E"
                    child
                />
                <tiptap-button
                    icon="align-justify"
                    label="Align justify"
                    extension="textAlign"
                    command="setTextAlign"
                    command-params="justify"
                    shortcut="Mod+Shift+J"
                    child
                />
            </tiptap-dropdown>
        </tiptap-button-group>

        <tiptap-button-group v-if="extensions.has(['link', 'variable'])">
            <tiptap-button-link
                extension="link"
                icon="link"
                label="Link"
            />
            <tiptap-dropdown
                #default="{ variable }"
                extension="variable"
                icon="brackets-curly"
                label="Variable"
            >
                <tiptap-button
                    v-for="name in variable.variables"
                    :key="name"
                    :label="`${name}`"
                    extension="variable"
                    command="setVariable"
                    :command-params="{ name }"
                    child
                />
            </tiptap-dropdown>
        </tiptap-button-group>
    </div>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import { BTabs, BTab } from 'bootstrap-vue'
    import { Inject } from './TiptapEditorProvider.vue'
    import TiptapButtonGroup from './TiptapButtonGroup.vue'
    import TiptapDropdown from './TiptapDropdown.vue'
    import TiptapButton from './TiptapButton.vue'
    import TiptapButtonLink from './TiptapButtonLink.vue'
    import TiptapDropdownHeading from './TiptapDropdownHeading.vue'

    export default (Vue as VueConstructor<Vue & Inject>).extend({
        name: 'TiptapToolbar',
        components: {
            BTabs,
            BTab,
            TiptapButtonGroup,
            TiptapDropdown,
            TiptapButton,
            TiptapButtonLink,
            TiptapDropdownHeading,
        },
        inject: ['editor', 'extensions'],
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .tiptap-toolbar-top {
        gap: spacer(2);
    }
</style>