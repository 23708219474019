import store from '@store'

export const initFullstory = (): void => {
    const organization: AppConfig = store.get('AppConfig/organization')
    const activeUserType: ActiveUserType = store.get('Auth/activeUserType')
    const authUser: UserModel = store.get('Auth/user')

    window.FS?.identify(authUser.id, {
        userRole: activeUserType,
        platformType: organization.type,
        domain: organization.domain,
        organizationId: organization.id,
    })
}
