<template>
    <base-modal
        :content-style="contentStyle"
        body-class="overflow-hidden p-0"
        size="lg"
        centered
        no-header
        no-footer
    >
        <iframe
            ref="knowbot"
            :src="`https://chat.applai.io/chatbot/${path}?waitForInit=true`"
            title="Knowbot"
            width="100%"
            height="100%"
            class="border-0"
            allowfullscreen
        />
    </base-modal>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { get } from 'vuex-pathify'
    import { DialogComponent } from 'vue-modal-dialogs'
    import { BaseModal } from '@common/Modals'

    import { buildFullPath } from '@common/Resources/utils'
    import { getInit } from '../utils'

    export default (Vue as VueConstructor<
        Vue &
        DialogComponent<void>
    >).extend({
        components: {
            BaseModal,
        },

        props: {
            path: {
                type: String as PropType<string>,
                required: true,
            },
        },

        data() {
            return {
                targetOrigin: 'https://chat.applai.io',
            }
        },

        computed: {
            imagePath: get<UserModel['image_path']>('Auth/authUser@image_path'),

            contentStyle(): object {
                return {
                    minHeight: this.$device.isDesktop ? '80vh' : '90vh',
                }
            },
        },

        created() {
            window.addEventListener('message', this.message.bind(this))
        },

        beforeDestroy(): void {
            window.removeEventListener('message', this.message.bind(this))
        },

        methods: {
            message(event: MessageEvent): void {
                if (event.origin !== this.targetOrigin)
                    return

                if (event.data.type === 'source-clicked')
                    window.open(event.data.url, '_blank')

                if (event.data.type === 'close')
                    this.$close()

                if (event.data.type === 'init') {
                    const iframe = this.$refs.knowbot as HTMLIFrameElement

                    const data: Partial<KnowbotChatConfig> = {
                        ...(this.imagePath && {
                            customAvatarImageUrl: buildFullPath(this.imagePath),
                        }),
                    }
                    const config = getInit(data)

                    iframe?.contentWindow?.postMessage(config, this.targetOrigin)
                }
            },
        },
    })
</script>
