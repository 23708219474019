import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'

const routerConfig = {
    baseURI: 'competences',
    module: 'Competences',
    middleware: [
        auth('admin', { name: '401' }),
        hasFeatures('Competence', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    {
        path: '',
        name: 'competences.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Competences" */
                './CompetencesIndex.vue'
            ),
    },
    {
        path: 'categories',
        name: 'competences.categories.index',
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Competences" */
                './CompetenceCategoryIndex.vue'
            ),
    },
])
