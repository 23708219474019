<template>
    <card
        class="course-card pointer"
        :class="{
            'card-hover pointer': clickable,
        }"
        border="none"
        :body-class="{ 'pt-1 pb-0': !!horizontalLocal }"
        :footer-class="{ 'pb-1': !!horizontalLocal }"
        :horizontal="horizontalLocal"
        @click="onClick"
    >
        <template #before>
            <div class="position-relative">
                <image-lazy
                    :src="value.thumbnailPath"
                    :ar="'16:9'"
                    :class="[horizontalLocal ? 'rounded-left' : 'rounded-top']"
                    :no-lazy="noLazyImg"
                    fill-blur
                >
                    <template #fallback>
                        <div
                            class="embed-responsive embed-responsive-16by9 bg-light text-gray-dark"
                            :class="[ horizontalLocal ? 'rounded-left' : 'rounded-top' ]"
                        >
                            <inline-svg
                                class="centerer w-35"
                                :src="require('@assets/icons/icon-calendar.svg')"
                            />
                        </div>
                    </template>
                </image-lazy>
                <div class="position-absolute bottom-0 w-100 d-flex align-items-end g-2">
                    <calendar-icon
                        v-if="attentionSessionDate"
                        class=""
                        :style="!horizontalLocal ? 'transform: translateY(0.5rem)' : null"
                        :class="{
                            'ml-3': !horizontalLocal
                        }"
                        :date="attentionSessionDate"
                        :variant="userVariant"
                    />
                    <div
                        v-if="attentionSession"
                        class="ml-auto badge badge-pill rounded-0 rounded-top-left mw-100 text-truncate"
                        :class="attentionSession.variant"
                        v-text="attentionSession.text"
                    />
                </div>
            </div>
        </template>
        <template #default>
            <small
                class="text-muted"
                v-text="$t('TERMS.COURSE')"
            />
            <h6
                v-line-clamp:[lineClampArg]="2"
                class="font-weight-bold mb-0"
                v-text="value.name"
            />
        </template>

        <template #footer>
            <small
                v-if="value.sessionCount && !isExplorable"
                class="text-muted"
                v-text="$tc('SESSION.COUNT', value.sessionCount)"
            />
        </template>
    </card>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import Card, { HorizontalOptions } from '@common/Card/Card.vue'
    import CalendarIcon from '@common/components/CalendarIcon.vue'
    import ImageLazy from '@common/Images/ImageLazy.vue'
    import { TranslateResult } from 'vue-i18n'
    import { getUserStates, getUserVariant } from '../utils/user-session'

    interface ButtonInfo {
        variant: string;
        text: TranslateResult;
    }

    export default Vue.extend({
        components: {
            Card,
            CalendarIcon,
            ImageLazy,
        },

        props: {
            value: {
                type: Object as PropType<CourseDataModel>,
                required: true,
            },

            context: {
                type: Object as PropType<CourseContextModel>,
                default: null,
            },

            noLazyImg: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            horizontal: {
                type: [Boolean, Object] as PropType<boolean | HorizontalOptions>,
                default: false,
            },

            clickable: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Learning content explorable check
             */
            isExplorable: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            lineClampArg(): string | null {
                return this.$device.isDesktop ? 'fixed' : null
            },
            horizontalLocal(): boolean | HorizontalOptions {
                if (typeof this.horizontal === 'object')
                    return this.horizontal

                return this.horizontal
                    ? {
                        before: 'col-5',
                        row: 'align-items-center',
                    }
                    : false
            },

            dateRange(): string | null {
                if (!this.context.sessionAttention) return null
                const attention = this.context.sessionAttention
                const startDate = attention.dates[0].date

                return this.$filter('daterange')((startDate), 'ddd, MMM D')
            },

            timeRange(): string {
                return this.context.sessionAttention?.dates[0].start_time.slice(0, 5)
            },

            userStates(): SessionUserStatesModel {
                return getUserStates(this.context.sessionAttention)
            },

            attentionSessionDate(): string | null {
                if (!this.context.sessionAttention) return null

                return this.context.sessionAttention.dates[0].date
            },

            userVariant(): string | null {
                return getUserVariant(this.isExplorable, this.context.sessionAttention)
            },

            attentionSession(): ButtonInfo | null {
                const dateTimeDisplay = !this.horizontalLocal
                    ? `${this.dateRange}, ${this.timeRange}`
                    : ''

                if (!this.context.sessionAttention || !this.context.sessionAttention.userStatus) {
                    if (this.isExplorable || !this.userStates.isPlanned) return null

                    return {
                        variant: 'badge-gray',
                        text: this.$t('LEARNING.COURSE_SESSION_INVITE'),
                    }
                }

                if (typeof this.context.sessionAttention?.attended === 'boolean')
                    return {
                        variant: 'badge-gray',
                        text: this.context.sessionAttention.attended
                            ? `${this.$t('SESSION.USER.STATUS.ATTENDED')} ${dateTimeDisplay}`
                            : `${this.$t('SESSION.USER.STATUS.NOT_ATTENDED')} ${dateTimeDisplay}`,
                    }

                if (this.userStates.isAttending)
                    return {
                        variant: this.userStates.isPlanned ?  'badge-success' : 'badge-gray',
                        text: `${this.$t('SESSION.USER.STATUS.ENROLLED')} ${dateTimeDisplay}`,
                    }

                if (this.context.sessionAttention.userStatus === 'invited' && this.userStates.isPlanned) {
                    if (this.context.sessionAttention.signupDeadline)
                        return {
                            variant: 'badge-danger',
                            text: `${this.$t('COURSE.SESSION_REGISTER_BEFORE')} ${this.$filter('daterange')([this.context.sessionAttention.signupDeadline], 'ddd, MMM D')}`,
                        }
                    else
                        return {
                            variant: 'badge-danger',
                            text: this.$t('COURSE.SESSION_RESPOND_INVITATION'),
                        }
                }

                if (this.userStates.isRequested)
                    return {
                        variant: this.userStates.isPlanned ?  'badge-yellow' : 'badge-gray',
                        text: `${this.$t('SESSION.USER.STATUS.REQUESTED')} ${dateTimeDisplay}`,
                    }

                if (this.userStates.isDeclining)
                    return {
                        variant: this.userStates.isPlanned ? 'badge-outline badge-danger' : 'badge-gray',
                        text: `${this.$t('SESSION.USER.STATUS.DECLINED')} ${dateTimeDisplay}`,
                    }

                if (this.userStates.isWaiting)
                    return {
                        variant: this.userStates.isPlanned ? 'badge-outline badge-warning' : 'badge-gray',
                        text: `${this.$t('SESSION.USER.STATUS.WAITINGLIST')} ${dateTimeDisplay}`,
                    }

                return null
            },
        },

        methods: {
            onClick(): void {
                if (!this.clickable)
                    return

                this.$router.push({
                    name: 'learning.events.details',
                    params: {
                        courseId: this.value.id.toString(),
                    },
                })
            },
        },
    })
</script>
