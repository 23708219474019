<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { Editor } from '@tiptap/vue-2'
    import { ExtensionManager } from './utils'

    export interface Inject {
        editor: Editor;
        extensions: ExtensionManager;
    }

    /**
     * Editor instance provider
     *
     * - Helper component that wraps our main editor and provides
     *   the editor instance, register via `inject`
     */
    export default Vue.extend({

        provide() {
            return {
                editor: this.editor,
                extensions: this.extensions,
            }
        },
        props: {
            editor: {
                type: Object as PropType<Editor>,
                required: true,
            },
            extensions: {
                type: Object as PropType<ExtensionManager>,
                required: true,
            },
        },

        render(h): any {
            return this.$scopedSlots.default?.({}) ?? h()
        },

    })
</script>