<template>
    <div
        class="card"
        :class="cardClass"
        :style="aspectRatioStyle"
        v-on="$listeners"
        @mouseover="hover(true)"
        @mouseleave="hover(false)"
    >
        <conditional-root :show="!!horizontal && !!($slots.before || $slots.after)">
            <div :class="getWrapperClasses('row')">
                <div
                    v-if="$slots.before"
                    :class="getWrapperClasses('before')"
                >
                    <!--
                        @slot Before card content
                            - apply content before all card elements, useful fx image
                    -->
                    <slot name="before" />
                </div>
                <conditional-root :show="!!horizontal">
                    <div :class="getWrapperClasses('content')">
                        <div
                            v-if="$slots.header"
                            class="card-header"
                            :class="headerClass"
                        >
                            <!-- @slot Card header content -->
                            <slot name="header" />
                        </div>
                        <div
                            v-if="$slots.content || $slots.default"
                            class="card-body"
                            :class="bodyClass"
                        >
                            <!-- @slot **DEPRECATED** - use default slot instead -->
                            <slot name="content" />
                            <!-- @slot Content to place in the body of the card  -->
                            <slot />
                        </div>
                        <div
                            v-if="$slots.footer"
                            class="card-footer"
                            :class="footerClass"
                        >
                            <!-- @slot Card footer content -->
                            <slot name="footer" />
                        </div>
                    </div>
                </conditional-root>
                <div
                    v-if="$slots.after"
                    :class="getWrapperClasses('after')"
                >
                    <!-- @slot After card content
                        - apply content after all card elements, useful fx image
                    -->
                    <slot name="after" />
                </div>
            </div>
        </conditional-root>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import AspectRatioMixin from '@common/mixins/AspectRatioMixin'
    import ConditionalRoot from '@common/components/ConditionalRoot.vue'

    export type HorizontalOptions = {
        /** Classes added to the row wrapper with in the .card */
        row?: string | any[] | object;

        /** Classes added to the before column */
        before?: string | any[] | object;

        /**
         * Classes added to the content wrapper column
         * - This houses the header, body and footer parts of the card
         */
        content?: string | any[] | object;

        /** Classes added to the before column */
        after?: string | any[] | object;
    }

    type HorizontalOptionName = keyof HorizontalOptions

    /**
     * Card Component
     *
     * This is our standard card component.
     *
     * **Notable features**
     *
     * - Emphasis weights
     *      - low: outline card, border variants, no background
     *      - medium: small shadow, background variants
     *      - hight: thick shadow, background variants
     * - Variant support
     *      - high & medium emphasis: applies color to background, foreground is also set automatically
     *      - low emphasis: applies border and bottom solid shadow with variant color
     * - Spacing
     *      - X & Y spacing on cards and between card elements
     * - Horizontal mode
     *      - With horizontal mode you can display the cards content and the before & after
     *        slots horizontally, useful to display images on left or right side of card content.
     *      - Horizontal property takes a boolean or an `HorizontalOptions` object for adding classes
     *        to grid wrappers needed.
     */
    export default (Vue as VueConstructor<
        Vue
        & InstanceType<typeof AspectRatioMixin>
    >).extend({
        components: {
            ConditionalRoot,
        },

        mixins: [AspectRatioMixin],

        props: {
            /**
             * Card weight emphasis
             */
            emphasis: {
                type: String as PropType<string>,
                default: 'medium',
                validator: (value: string): boolean => [
                    'high',
                    'medium',
                    'low',
                    'none',
                ].includes(value),
            },

            /**
             * Horizontal and vertical spacing on card
             */
            spacing: {
                type: String as PropType<string>,
                default: 'md',
                validator: (value: string): boolean => [
                    'sm',
                    'md',
                    'lg',
                ].includes(value),
            },

            /**
             * Color variant for card
             */
            variant: {
                type: String as PropType<string>,
                default: 'default',
                validator: (value: string): boolean => [
                    'default',
                    'primary',
                    'secondary',
                    'accent',
                    'success',
                    'warning',
                    'danger',
                    'off-white',
                    'link',
                    'expert-blue',
                    'gray',
                    'gray-light',
                    'neutral-5',
                ].includes(value),
            },

            fill: {
                type: String as PropType<string>,
                default: 'solid',
                validator: (value: string): boolean => [
                    'solid',
                    'dimmed',
                ].includes(value),
            },

            border: {
                type: String as PropType<string>,
                default: 'solid',
                validator: (value: string): boolean => [
                    'solid',
                    'dashed',
                    'none',
                ].includes(value),
            },

            /**
             * Horizontal card mode.
             * This will wrap the contents of your card in three rows depending on what slots you have.
             * You can pass a boolean to have equal width cols or customize with col-* classes if you
             * pass an `HorizontalOptions` object as value.
             *
             * @example
             * ```
             * <card
             *   horizontal="{
             *     row: 'row-reverse',
             *     before: condition ? 'col-3' : 'col-6',
             *     content: ['col-6', 'another-class'],
             *     after: { 'col-3': condition },
             *   }"
             * />
             * ```
             */
            horizontal: {
                type: [Boolean, Object] as PropType<boolean | HorizontalOptions>,
                default: null,
                validator: (value: boolean | HorizontalOptions): boolean => {
                    if (typeof value === 'boolean') return true

                    return Object.keys(value).every((key) => [
                        'row',
                        'before',
                        'content',
                        'after',
                    ].includes(key))
                },
            },

            /**
             * Classes to apply on `card-header`
             */
            headerClass: {
                type: [String, Array, Object] as PropType<string | any[] | object>,
                default: null,
            },

            /**
             * Classes to apply on `card-body`
             */
            bodyClass: {
                type: [String, Array, Object] as PropType<string | any[] | object>,
                default: null,
            },

            /**
             * Classes to apply on `card-footer`
             */
            footerClass: {
                type: [String, Array, Object] as PropType<string | any[] | object>,
                default: null,
            },

            /**
             * Classes to apply when card is being hovered
             */
            hoverClass: {
                type: [String, Array, Object] as PropType<string | any[] | object>,
                default: null,
            },
        },

        data() {
            return {
                hovering: false,
            }
        },

        computed: {
            cardClass(): (string | object)[] {
                const classes: (string | object)[] =  [
                    `card--${this.emphasis}`,
                    `card--${this.variant}`,
                    `card--sp-${this.spacing}`,
                    `card--border-${this.border}`,
                    `card--fill-${this.fill}`,
                ]

                if (this.hovering)
                    classes.push(this.hoverClass)

                return classes
            },
        },

        methods: {
            hover(value: boolean): void {
                this.hovering = value
            },

            getWrapperClasses(name: HorizontalOptionName): any[] | void {
                if (!this.horizontal) return

                const getOpt = (name: HorizontalOptionName): any => typeof this.horizontal !== 'boolean'
                    ? this.horizontal[name]
                    : ''

                const map: Record<string, any[]> = {
                    before: ['col', getOpt('before')],
                    after: ['col', getOpt('after')],
                    row: ['row h-100 no-gutters', getOpt('row')],
                    content: ['col d-flex flex-column', getOpt('content')],
                }

                return map[name]
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    @mixin card-border($color, $style: dashed) {
        // eslint-disable-next-line declaration-no-important
        border: $border-width * 2 $style $color;
    }

    // Variant mixin for low emphasis cards
    @mixin card-low($color) {
        border: $border-width solid $color;
        /* stylelint-disable-next-line declaration-no-important */
        box-shadow: 0 ($border-width * 2) 0 0 $color !important;
    }

    // Spacing mixin for xy spacing of card
    // also removes double spacing between card-<element> items
    @mixin card-spacing($spacer) {
        > .card-header, > .card-body, > .card-footer {
            padding: $spacer;
        }

        .card-header + .card-body,
        .card-header + .card-footer,
        .card-body + .card-footer {
            padding-top: 0;
        }
    }

    // Card variants <variant-name>: <background> <foreground>
    $cardVariants: (
        primary: whitelabel-color('primary') whitelabel-color('primary-txt'),
        secondary: whitelabel-color('secondary') whitelabel-color('secondary-txt'),
        accent: whitelabel-color('accent') whitelabel-color('accent-txt'),
        off-white: $body-bg $body-color,
        gray: color('gray') $body-color,
        gray-light: color('gray-light') $white,
        success: $success $white,
        warning: $warning $body-color,
        danger: $danger $white,
        link: whitelabel-color('anchor') $white,
        expert-blue: $lb-expert-blue $white,
        neutral-5: color('neutral-5') $body-color,
    );

    $cardSpacing: (
        sm: spacer(2),
        md: spacer(3),
        lg: spacer(4),
    );

    .card {
        $self: &;

        @each $spaceName, $spacer in $cardSpacing {
            &--sp-#{ $spaceName } {
                @include card-spacing($spacer);
            }
        }

        // High emphasis card
        &.card--high {
            box-shadow: $box-shadow !important;
        }

        // High & Medium emphasis card variants
        &.card--high, &.card--medium {
            @each $variant, $colors in $cardVariants {
                $background: nth($colors, 1);
                $foreground: nth($colors, 2);

                &#{$self}--#{ "#{$variant}" } {
                    background: $background;
                    color: $foreground;

                    // TODO - add dimmed variant
                    // &.card--fill-dimmed {
                    //     background: rgba($background, .5)
                    // }
                }
            }
        }

        // Low emphasis card
        &.card--low {
            background: transparent;

            &#{ $self }--default {
                @include card-low($border-color);
            }

            @each $variant, $colors in $cardVariants {
                $color: nth($colors, 1);

                &#{$self}--#{ "#{$variant}" } {
                    @include card-low($color);
                }
            }
        }

        // No emphasized card
        &.card--none {
            background: transparent;
            box-shadow: none !important;

            @each $variant, $colors in $cardVariants {
                $color: nth($colors, 1);

                &#{$self}--#{ "#{$variant}" } {
                    /* stylelint-disable-next-line declaration-no-important */
                    box-shadow: none !important;
                }
            }
        }

        &.card--border-dashed {
            &#{ $self }--default {
                @include card-border($border-color);
            }

            @each $variant, $colors in $cardVariants {
                $color: nth($colors, 1);

                &#{$self}--#{ "#{$variant}" } {
                    @include card-border($color);
                }
            }
        }

        &.card--border-none {
            border: none;
        }

        // Fix position of fa stack in card headers.
        .card-header .fa-stack {
            margin-left: -.5rem;
        }
    }
</style>

<docs>
### High emphasis card examples
```vue
    <div class="row">
        <div class="col-6 mb-3">
            <card emphasis="high">
                Hello world, I'm a hight emphasis card
            </card>
        </div>
        <div class="col-6 mb-3">
            <card emphasis="high">
                <template #header>
                    <strong>My header</strong>
                </template>
                Hello, I'm a card with a header & footer
                <template #footer>
                    <i>My footer</i>
                </template>
            </card>
        </div>
    </div>

    <h5>Card variants</h5>
    <div class="row">
        <div
            class="col-6 mb-3"
            v-for="variant in ['primary', 'secondary', 'accent', 'success', 'warning', 'danger', 'link']"
        >
            <card :variant="variant" emphasis="high">
                A high emphasis card with <strong>{{ variant }}</strong> variant
            </card>
        </div>
    </div>
```
### Medium emphasis card examples (Default)
```vue
    <div class="row">
        <div class="col-6 mb-3">
            <card>
                Hello world, I'm a basic card
            </card>
        </div>
        <div class="col-6 mb-3">
            <card>
                <template #header>
                    <strong>My header</strong>
                </template>
                Hello, I'm a card with a header & footer
                <template #footer>
                    <i>My footer</i>
                </template>
            </card>
        </div>
    </div>

    <h5>Card variants</h5>
    <div class="row">
        <div
            class="col-6 mb-3"
            v-for="variant in ['primary', 'secondary', 'accent', 'success', 'warning', 'danger', 'link']"
        >
            <card :variant="variant">
                A basic card with <strong>{{ variant }}</strong> variant
            </card>
        </div>
    </div>
```

### Low emphasis card examples
```vue
    <div class="row">
        <div class="col-6 mb-3">
            <card emphasis="low">
                Hello world, I'm a lite card
            </card>
        </div>
        <div class="col-6 mb-3">
            <card emphasis="low">
                <template #header>
                    <strong>My header</strong>
                </template>
                Hello, I'm a card with a header & footer
                <template #footer>
                    <i>My footer</i>
                </template>
            </card>
        </div>
    </div>
    <h5>Card variants</h5>
    <div class="row">
        <div
            class="col-6 mb-3"
            v-for="variant in ['primary', 'secondary', 'accent', 'success', 'warning', 'danger', , 'link']"
        >
            <card :variant="variant" emphasis="low">
                A basic card with <strong>{{ variant }}</strong> variant
            </card>
        </div>
    </div>
```

### Horizontal cards
```vue
<div class="row">
    <div class="col-6 mb-4">
        <card horizontal>
            <template #before>
                <img class="img-fluid rounded-left" src="https://picsum.photos/200/200"/>
            </template>
            <template #header>
                <h5 class="mb-0">Horizontal Card</h5>
            </template>
            Basic horizontal card with image in <code>before</code> slot
            <template #footer>
                <small>My footer</small>
            </template>
        </card>
    </div>
    <div class="col-6 mb-4">
        <card horizontal>
            <template #after>
                <img class="img-fluid rounded-right" src="https://picsum.photos/200/200"/>
            </template>
            <template #header>
                <h5 class="mb-0">Horizontal Card</h5>
            </template>
            Basic horizontal card with image in <code>after</code> slot
            <template #footer>
                <small>My footer</small>
            </template>
        </card>
    </div>
    <div class="col-12">
        <card :horizontal="{
            before: 'col-2'
        }">
            <template #before>
                <img class="img-fluid rounded-left" src="https://picsum.photos/200/200"/>
            </template>
            <template #header>
                <h5 class="mb-0">Horizontal Card</h5>
            </template>
            Using horizontal options to adjust card's inner grid
            <template #footer>
                <small>My footer</small>
            </template>
        </card>
    </div>
</div>

```
</docs>
