import { Extension } from '@tiptap/vue-2'
import { Color } from '@tiptap/extension-color'
import { Highlight } from '@tiptap/extension-highlight'
import { COLORS } from '@utils'

export interface TextColorOptions {
    colors: string[];
    text: boolean;
    highlight: boolean;
}

const TextColor = Extension.create<TextColorOptions>({
    name: 'textColor',

    addOptions() {
        return {
            colors: COLORS,
            text: true,
            highlight: true,
        }
    },

    addExtensions() {
        const extensions = []

        if (this.options.text)
            extensions.push(Color)

        if (this.options.highlight)
            extensions.push(Highlight.configure({ multicolor: true }))

        return extensions
    },
})

export default TextColor