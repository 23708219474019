<template>
    <tiptap-editor-provider
        v-if="editor"
        :editor="editor"
        :extensions="extensions"
    >
        <div
            class="tiptap-editor d-flex flex-column"
            :class="[`tiptap-editor--${mode}`, { disabled }]"
        >
            <slot
                name="before"
                :editor="editor"
                :extensions="extensions"
            />
            <tiptap-toolbar-top
                v-if="toolbar"
            />
            <editor-content
                class="tiptap-editor__content"
                :editor="editor"
                :class="contentClass"
                :style="contentStyle"
            />

            <tiptap-character-counter
                v-if="options.showCharacterCount"
                class="mt-auto text-right"
                :count="characterCount"
                :limit="options.limit"
            />

            <slot
                name="after"
                :editor="editor"
                :extensions="extensions"
            />
        </div>
    </tiptap-editor-provider>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { Editor, EditorContent, Extensions } from '@tiptap/vue-2'
    import { ExtensionManager } from './utils'

    import TiptapEditorProvider from './TiptapEditorProvider.vue'
    import TiptapToolbarTop from './TiptapToolbarTop.vue'
    import TiptapCharacterCounter from './TiptapCharacterCounter.vue'

    import Configuration, { TiptapOptions } from './extensions/configuration'
    import { EDITOR_MODES } from './utils'


    /**
     * Tiptap editor
     */
    export default Vue.extend({
        components: {
            TiptapEditorProvider,
            TiptapToolbarTop,
            TiptapCharacterCounter,
            EditorContent,
        },

        props: {
            /**
             * The editor content
             * @model
             */
            value: {
                type: String as PropType<string>,
                default: '',
            },
            /**
             * Is editor disabled
             */
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            /**
             * Editor options
             */
            options: {
                type: Object as PropType<TiptapOptions>,
                default: undefined,
            },
            /**
             * Add any custom plugins/extensions that are not a part
             * of the options configuration.
             */
            plugins: {
                type: Array as PropType<Extensions>,
                default: (): never[] => [],
            },
            /**
             *  Editor modes
             */
            mode: {
                type: String as PropType<typeof EDITOR_MODES[number]>,
                default: EDITOR_MODES[0],
                validator: (value: typeof EDITOR_MODES[number]): boolean => EDITOR_MODES.includes(value),
            },
            /**
             * Show main toolbar
             */
            toolbar: {
                type: Boolean as PropType<boolean>,
                default: true,
            },
            /**
             * Classes to be applied on the editor content
             */
            contentClass: {
                type: [String, Array, Object] as PropType<string | any[] | object>,
                default: undefined,
            },
            /**
             * Styles to be applied on the editor content
             */
            contentStyle: {
                type: [String, Array, Object] as PropType<string | any[] | object>,
                default: undefined,
            },
        },

        data() {
            return {
                defaultOptions: {
                    multiLine: true,
                },
                editor: null as Editor | null,
                extensions: null as ExtensionManager | null,
                jsonOutput: null as any,
                textOutput: null as any,
            }
        },

        computed: {
            internalOptions(): any {
                return Object.assign(this.defaultOptions, this.options)
            },

            characterCount(): number {
                return this.editor?.storage.characterCount?.characters?.() ?? 0
            },
        },

        watch: {
            value(value): void {
                if (this.editor?.getHTML() === value) return

                this.editor?.commands.setContent(value, false)
            },

            disabled(value): void {
                this.editor?.setEditable(!value)

                if (this.options.autofocus)
                    this.focus()
            },
        },

        mounted() {
            this.initEditor()
        },

        beforeDestroy() {
            this.editor?.destroy()
        },

        methods: {
            initEditor(): void {
                const editor = new Editor({
                    editable: !this.disabled,
                    content: this.value,
                    extensions: [
                        Configuration.configure(this.internalOptions),
                        ...this.plugins,
                    ],
                    autofocus: this.options.autofocus ? 'end' : false,
                    onUpdate: (ctx): void => {
                        this.$emit('input', this.editor?.getHTML())
                        this.$emit('update', ctx)
                    },
                    onFocus: (ctx): void => {
                        this.$emit('focus', ctx)
                    },
                    onBlur: (ctx): void => {
                        this.$emit('blur', ctx)
                    },
                })
                this.extensions = new ExtensionManager(editor)
                this.editor = editor
            },

            focus(): void {
                this.editor?.commands.focus('end')
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .tiptap-editor {
        $root: &;

        ::v-deep .ProseMirror:focus {
            outline: none;
        }

        // Custom styling for when editor is in field mode
        &--field {
            border: $border-width solid $input-border-color;
            border-radius: $border-radius;
            background: $white;

            &.disabled {
                background: $input-disabled-bg;
            }

            #{$root}__content {
                padding: $input-padding-y $input-padding-x;
                overflow-y: auto;
            }

            #{$root}__counter {
                text-align: right;
                padding: 0 $input-padding-x $input-padding-y;
            }

            .tiptap-toolbar-top {
                border-bottom: $border-width solid $input-border-color;
                padding: spacer(2);
            }
        }

        // TODO: Custom styling when editor is in inline mode
        // &--inline { }
    }

    //--------------------------------------------------
    // Overwrite any styling with in the editor view
    // -------------------------------------------------
    ::v-deep .ProseMirror {
        h1,h2,h3,h4,h5,h6 {
            font-weight: normal;
        }

        p:last-child {
            margin-bottom: 0;
        }

        .is-empty:first-child::before {
            content: attr(data-placeholder);
            float: left;
            color: $input-placeholder-color;
            pointer-events: none;
            height: 0;
        }


        //----------------------------------------------------
        // Extension specific styling
        // ---------------------------------------------------

        // Variable extension
        span.variable {
            display: inline-block;
            padding: 0 spacer(1);
            background-color: rgba($black, .08);
            box-shadow: inset 0 0 0 $border-width rgba($black, .1);
            border-radius: $border-radius;
        }

        span.mention {
            color: $link-color;
        }
    }
</style>
