<template>
    <span>
        <b-dropdown-divider class="divider" />

        <b-dropdown-group>
            <!-- Switch user type -->
            <template v-if="canAdmin || canClient">
                <b-dropdown-item-button
                    v-if="canAdmin"
                    button-class="navigation-item__menu__btn"
                    @click="switchUserType('admin')"
                >
                    <fa
                        v-if="showIcons"
                        class="mr-1"
                        :icon="['fal', 'arrow-up-arrow-down']"
                        fixed-width
                    />
                    {{ $t('NAV.ADMIN') }}
                </b-dropdown-item-button>
                <b-dropdown-item-button
                    v-if="canClient"
                    button-class="navigation-item__menu__btn"
                    @click="switchUserType('client')"
                >
                    <fa
                        v-if="showIcons"
                        class="mr-1"
                        :icon="['fal', 'arrow-up-arrow-down']"
                        fixed-width
                    />
                    {{ $t('NAV.USER') }}
                </b-dropdown-item-button>
            </template>
            <!-- Manage Dasboard -->
            <b-dropdown-item
                v-if="userType === 'admin' && hasClientAccess"
                link-class="navigation-item__menu__btn"
                :to="{ name: 'dashboard.manage' }"
            >
                <fa
                    v-if="showIcons"
                    class="mr-1"
                    :icon="['fal', 'table-layout']"
                    fixed-width
                />
                {{ $t('USER.DASHBOARD.MANAGE') }}
            </b-dropdown-item>
            <!-- Settings -->
            <b-dropdown-item
                :to="{
                    name: userType === 'lb'
                        ? 'settings.user'
                        : 'userProfile.settings'
                }"
                link-class="navigation-item__menu__btn"
            >
                <fa
                    v-if="showIcons"
                    class="mr-1"
                    :icon="['fal', 'cog']"
                    fixed-width
                />
                {{ $t('NAV.SETTINGS') }}
            </b-dropdown-item>
            <!-- Help -->
            <b-dropdown-item
                v-if="activeUserType === 'admin'"
                link-class="navigation-item__menu__btn"
                href="https://help.learningbank.io"
                target="blank"
            >
                <fa
                    v-if="showIcons"
                    class="mr-1"
                    :icon="['fal', 'circle-info']"
                    fixed-width
                />
                {{ $t('TERMS.HELP') }}
            </b-dropdown-item>

            <b-dropdown-item-button
                v-if="activeUserType === 'client'"
                button-class="navigation-item__menu__btn"
                @click="openContactModal"
            >
                <fa
                    v-if="showIcons"
                    class="mr-1"
                    :icon="['fal', 'circle-info']"
                    fixed-width
                />
                {{ $t('TERMS.HELP') }}
            </b-dropdown-item-button>
        </b-dropdown-group>

        <b-dropdown-divider class="divider" />

        <!-- Logout -->
        <b-dropdown-item-button
            button-class="navigation-item__menu__btn"
            @click="logout"
        >
            <fa
                v-if="showIcons"
                class="mr-1"
                :icon="['fal', 'sign-out']"
                fixed-width
            />
            {{ $t('NAV.LOG_OUT') }}
        </b-dropdown-item-button>
    </span>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { get } from 'vuex-pathify'
    import { BDropdownItemButton, BDropdownItem, BDropdownDivider, BDropdownGroup } from 'bootstrap-vue'
    import { clearHubspot } from '@modules/Auth/utils/hubspot'

    const CommunicationContactModal = (): Promise<any> => import(
        /* webpackChunkName: "modules/Communication" */
        '@modules/Communication/components/CommunicationContactModal.vue'
    )

    export default Vue.extend({
        components: {
            BDropdownItem,
            BDropdownItemButton,
            BDropdownDivider,
            BDropdownGroup,
        },

        props: {
            /**
             * Display icons on every item
             */
            showIcons: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            userType: get<'lb' | 'admin' | 'client'>('Auth/userType'),
            activeUserType: get<string>('Auth/activeUserType'),
            hasClientAccess: get<boolean>('Auth/hasClientAccess'),

            canClient(): boolean {
                return (
                    !['client', 'lb'].includes(this.activeUserType) &&
                    this.hasClientAccess
                )
            },

            canAdmin(): boolean {
                return (
                    this.userType === 'admin' &&
                    !['admin', 'lb'].includes(this.activeUserType)
                )
            },
        },

        methods: {
            async openContactModal(): Promise<void> {
                const { default: communicationContactModal } = await CommunicationContactModal()
                this.$dialog.modal(communicationContactModal)
            },

            switchUserType(type: 'lb' | 'admin' | 'client'): void {
                this.$store.dispatch('Auth/switchUserType', type)
            },

            logout(): void {
                clearHubspot()
                this.$router.replace({ name: 'auth.signout' })
            },
        },
    })
</script>

<style lang="scss" scoped>
    .divider + .divider {
        display: none;
    }
</style>