/**
 * Strip html tags from string
 *
 * @param string
 * @param preserveLineBreaks preserve line breaks (\n) on block elements
 */
export const stripTags = (string: string, preserveLineBreaks = false): string => {
    if (preserveLineBreaks) {
        string = string
            // Preserve line breaks for block elements
            .replace(/(<\/(div|br|li|ul|ol|h[1-6])[^>]*>)/gi, '\n')
            // Preserve two line breaks for </p> tags
            .replace(/<\/p>/gi, '\n\n')
    }

    return string
        .replace(/(<([^>]+)>)/gi, '')
        .replace(/&nbsp;|&amp;/g, ' ')
}

/**
 * Convert string to snakecase
 * @param string
 */
export function snakeCase(string: string): string {
    return string
        .replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map((word) => word.toLowerCase())
        .join('_')
}

/**
 * Generates a unique string
 *
 * @param length length of string to be generated
 */
export function getUniqueString(length = 16): string {
    return [...Array(length)]
        .map(() => (~~(Math.random() * 36)).toString(36))
        .join('')
}

/**
 * Get initials from a string, maximum 2 characters
 * blatantly borrowed from Stackoverflow
 *
 * @see https://stackoverflow.com/a/63763497
 *
 * @param value string
 * @returns string
 */
export function getInitials(value: string): string {
    return value.match(/(^\S\S?|\s\S)?/g)
        ?.map((v) => v.trim())
        .join('')
        .match(/(^\S|\S$)?/g)
        ?.join('')
        .toLocaleUpperCase() ?? ''
}

/**
 * Replaces placeholders defined with colon prefix.
 * - if match isn't found we fallback to original substring
 * - Placeholders are case-sensitive
 *
 * - Usage:
 *      replacePlaceholders('api2/foo/:fooId/bar/:barId', { fooId: 10, barid: 12 })
 *      output => 'api2/foo/10/bar/:barId'
 */
export function replacePlaceholders(value: string, vars: Record<string, any>): string {
    return value.replace(/:\w+/g, (match) => vars[match.slice(1)] || match)
}

/**
 * Extract placeholders defined with colon prefix
 * - returns placeholder names without color prefix as string[]
 * - returned placeholders are unique
 *
 * - Usage:
 *      extractPlaceholders('api2/foo/:fooId/bar/:barId/:fooId')
 *      output => ['fooId', 'barId']
 */
export function extractPlaceholders(value: string): string[] {
    const matches = value.match(/:\w+/g) || []
    const uniqueNames = new Set(matches.map((match) => match.slice(1)))

    return Array.from(uniqueNames)
}

export function lineBreakToHtml(value: string): string {
    return value.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

export function capitalize(value: string): string {
    if (!value) return ''

    return value.charAt(0).toUpperCase() + value.slice(1)
}
