import { RawLocation } from 'vue-router'
import { auth, hasFeatures } from '@middleware'
import routesFactory from '@router/routes-factory'
import { INSIGHT_SCOPES } from '../utils'

const routerConfig = {
    baseURI: 'insights/learners',
    module: 'Insights',
    middleware: [
        hasFeatures('InsightsLearners', {
            name: '403',
            params: {
                message: 'INSIGHTS.403.MESSAGE',
            },
        }),
        auth('admin', { name: '401' }),
    ],
}

export default routesFactory(routerConfig, [
    /**
     * User insights Routes
     */
    {
        path: 'users/:userId',
        name: 'insights.learners.user.index',
        redirect: (to): RawLocation => ({
            name: INSIGHT_SCOPES.get('USER_LEARNINGPATHS')?.routeName,
            query: {
                completion: 'required',
                ...to.query,
            },
        }),
        props: {
            nav: {
                'ACADEMY.TITLE': INSIGHT_SCOPES.get('USER_LEARNINGPATHS')?.routeName,
                'TERMS.MODULES': INSIGHT_SCOPES.get('USER_MODULES')?.routeName,
            },
            persistentQueries: ['completion', 'from', 'to'],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Insights" */
                '../InsightsGroupMaster.vue'
            ),
        children: [
            {
                path: 'modules',
                name: INSIGHT_SCOPES.get('USER_MODULES')?.routeName,
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "modules/Insights" */
                        '../InsightsLearnersUserModules.vue'
                    ),
            },
            {
                path: 'learning-paths',
                name: INSIGHT_SCOPES.get('USER_LEARNINGPATHS')?.routeName,
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "modules/Insights" */
                        '../InsightsLearnersUserContainers.vue'
                    ),
            },
        ],
    },

    /**
     * Division insights routes
     */
    {
        path: 'departments/:divisionId',
        name: 'insights.learners.division.index',
        redirect: (to): RawLocation => ({
            name: INSIGHT_SCOPES.get('DIVISION_LEARNINGPATHS')?.routeName,
            query: {
                'completion': 'required',
                'is-active': '1',
                ...to.query,
            },
        }),
        props: {
            nav: {
                'ACADEMY.TITLE': INSIGHT_SCOPES.get('DIVISION_LEARNINGPATHS')?.routeName,
                'TERMS.MODULES': INSIGHT_SCOPES.get('DIVISION_MODULES')?.routeName,
            },
            persistentQueries: ['completion', 'is-active', 'from', 'to'],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Insights" */
                '../InsightsGroupMaster.vue'
            ),
        children: [
            {
                path: 'modules',
                name: INSIGHT_SCOPES.get('DIVISION_MODULES')?.routeName,
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "modules/Insights" */
                        '../InsightsLearnersDivisionModules.vue'
                    ),
            },
            {
                path: 'learning-paths',
                name: INSIGHT_SCOPES.get('DIVISION_LEARNINGPATHS')?.routeName,
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "modules/Insights" */
                        '../InsightsLearnersDivisionContainers.vue'
                    ),
            },
        ],
    },

    /**
     * Title insights routes
     */
    {
        path: 'job-profiles/:titleId',
        name: 'insights.learners.title.index',
        redirect: (to): RawLocation => ({
            name: INSIGHT_SCOPES.get('TITLE_LEARNINGPATHS')?.routeName,
            query: {
                'completion': 'required',
                'is-active': '1',
                ...to.query,
            },
        }),
        props: {
            nav: {
                'ACADEMY.TITLE': INSIGHT_SCOPES.get('TITLE_LEARNINGPATHS')?.routeName,
                'TERMS.MODULES': INSIGHT_SCOPES.get('TITLE_MODULES')?.routeName,
            },
            persistentQueries: ['completion', 'is-active', 'from', 'to'],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Insights" */
                '../InsightsGroupMaster.vue'
            ),
        children: [
            {
                path: 'modules',
                name: INSIGHT_SCOPES.get('TITLE_MODULES')?.routeName,
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "modules/Insights" */
                        '../InsightsLearnersTitleModules.vue'
                    ),
            },
            {
                path: 'learning-paths',
                name: INSIGHT_SCOPES.get('TITLE_LEARNINGPATHS')?.routeName,
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "modules/Insights" */
                        '../InsightsLearnersTitleContainers.vue'
                    ),
            },
        ],
    },

    /**
     * Learners master pages
     */
    {
        path: '',
        name: 'insights.learners.index',
        redirect: {
            name: INSIGHT_SCOPES.get('USERS')?.routeName,
            query: {
                'completion': 'required',
                'is-active': '1',
            },
        },
        props: {
            nav: {
                'TERMS.USERS': INSIGHT_SCOPES.get('USERS')?.routeName,
                'STRUCTURE.DIVISIONS.TITLE': INSIGHT_SCOPES.get('DIVISIONS')?.routeName,
                'STRUCTURE.JOBPROFILES.TITLE': INSIGHT_SCOPES.get('TITLES')?.routeName,
            },
            header: {
                title: 'INSIGHTS.LEARNERS.TITLE',
                description: 'INSIGHTS.LEARNERS.INFO',
            },
            persistentQueries: ['completion', 'is-active'],
        },
        component: (): Promise<any> =>
            import(
                /* webpackChunkName: "modules/Insights" */
                '../InsightsGroupMaster.vue'
            ),
        children: [
            {
                path: 'users',
                name: INSIGHT_SCOPES.get('USERS')?.routeName,
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "modules/Insights" */
                        '../InsightsLearnersUsersIndex.vue'
                    ),
            },
            {
                path: 'departments',
                name: INSIGHT_SCOPES.get('DIVISIONS')?.routeName,
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "modules/Insights" */
                        '../InsightsLearnersDivisionsIndex.vue'
                    ),
            },
            {
                path: 'job-profiles',
                name: INSIGHT_SCOPES.get('TITLES')?.routeName,
                component: (): Promise<any> =>
                    import(
                        /* webpackChunkName: "modules/Insights" */
                        '../InsightsLearnersTitlesIndex.vue'
                    ),
            },
        ],
    },
])