<template>
    <tiptap-dropdown
        lazy
        extension="heading"
        label="Text style"
        v-bind="$attrs"
    >
        <template #button-content>
            <span
                class="d-inline-block"
                style="min-width: 5.8rem;"
            >
                <template v-for="level in heading.levels">
                    <template v-if="editor.isActive('heading', { level })">
                        {{ $t('TIPTAP.HEADING') }} {{ level }}
                    </template>
                </template>
                <template v-if="editor.isActive('paragraph')">
                    {{ $t('TIPTAP.PARAGRAPH') }}
                </template>
            </span>
            <fa :icon="['fal', 'angle-down']" />
        </template>

        <tiptap-button
            label="Paragraph"
            extension="paragraph"
            command="setParagraph"
            shortcut="Mod+Alt+0"
            child
        />

        <tiptap-button
            v-for="level in heading.levels"
            :key="level"
            :label="`Heading ${level}`"
            extension="heading"
            command="setHeading"
            :command-params="{ level }"
            :shortcut="`Mod+Alt+${level}`"
            child
        />
    </tiptap-dropdown>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import { HeadingOptions } from './extensions/heading'
    import TiptapDropdown from './TiptapDropdown.vue'
    import TiptapButton from './TiptapButton.vue'
    import { Inject } from './TiptapEditorProvider.vue'

    export default (Vue as VueConstructor<Vue & Inject>).extend({
        components: {
            TiptapDropdown,
            TiptapButton,
        },
        inject: ['editor', 'extensions'],
        inheritAttrs: false,

        computed: {
            heading(): HeadingOptions {
                return this.extensions.getOptions('heading') as HeadingOptions
            },
        },
    })
</script>