import { FontFamily, FontFamilyOptions as FontFamilyOptionsDefault } from '@tiptap/extension-font-family'
import { getFontFamilies } from '@utils'

export interface FontFamilyOptions extends FontFamilyOptionsDefault {
    fonts: [{
        value: string | null;
        text: string;
    }];
}

export default FontFamily.extend({
    addOptions() {
        return {
            ...this.parent?.(),
            fonts: getFontFamilies(true),
        }
    },
})